import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/layout/Layout";
import SEO from "../components/seo";

interface Props {}

const SymbolyPage = (props: Props) => {
  return (
    <Layout>
      <SEO title="Symboly" />
      <h1>Symboly</h1>
      <StaticImage
        src="../images/symboly/symboly.png"
        alt="Prací symboly"
        className="mb-10"
      />
      <p className="text-justify">
        Seznam ošetřovacích symbolů uvedených v normě ISO 3758. Znovu
        upozorňujeme - ošetřovací symboly jsou v České republice zapsány jako
        Ochraná známka č. 849 319. Majitelem této ochranné známky je mezinárodní
        asociace Ginetex, správcem pro Českou republiku je společnost Sotex
        Ginetex CZ. Používání a vysvětlení ošetřovacích symbolů je popsáno v
        mezinárodní normě ISO 3758. Podrobné informace najdete na stránkách
        www.sotex.cz
      </p>
    </Layout>
  );
};

export default SymbolyPage;
